import { template as template_1bb50e96ff5c470db8687e580db7d279 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import Component from '@glimmer/component';
const RULE_STYLES = {
    VALID: {
        iconClass: 'checkCircle',
        listItemClass: 'password-rule'
    },
    INVALID: {
        iconClass: 'cancel',
        listItemClass: 'password-rule password-rule--error'
    }
};
export default class PasswordRule extends Component {
    get classes() {
        return this.args.isValid ? RULE_STYLES.VALID : RULE_STYLES.INVALID;
    }
    static{
        template_1bb50e96ff5c470db8687e580db7d279(`
    <li class="{{this.classes.listItemClass}}" aria-label="{{@description}}.">
      <PixIcon @name={{this.classes.iconClass}} @plainIcon={{true}} @ariaHidden={{true}} />
      <p aria-live="polite"> {{@description}} </p>
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
