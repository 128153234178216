import { template as template_4fee62de69ef4521b87449c1c96f7993 } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { t } from 'ember-intl';
export default template_4fee62de69ef4521b87449c1c96f7993(`
  <PixNotificationAlert @type="communication" @withIcon="true" role="alert">
    {{t "pages.modulix.beta-banner"}}
  </PixNotificationAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
