import { template as template_c3ee5cd20318458b961da2dafee812c8 } from "@ember/template-compiler";
export default template_c3ee5cd20318458b961da2dafee812c8(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
