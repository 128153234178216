import { template as template_8317a9e7ffef4cd5a1b71eb799e134c8 } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_8317a9e7ffef4cd5a1b71eb799e134c8(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
